<template>
  <div class="ps-5 pe-5">
    <div class="row">
      <div class="col-12">
        <div class="">
          <div class="">
            <h6 class="dashboard-header">USER CALENDAR</h6>
          </div>
          <!-- TEMPORARY UNTIL FINISHED CONSTRUCTING IT -->
          <div class="mt-5">
            <div><font-awesome-icon icon="wrench" style="color: blue; font-size: 32px;" /> <font-awesome-icon icon="cogs" style="color: slategrey; font-size: 32px;" /></div>
            <p class="mb-0">UNDER CONSTRUCTION</p>
            <p class="mb-0">Feel free to use navigation on the left side of the screen</p>
          </div>
          <!-- TEMPORARY UNTIL FINISHED CONSTRUCTING IT -->

<!--          <div class="h-auto">-->

<!--            <div class="row h-100 align-items-center pt-4 pb-4">-->
<!--              <div class="col-12 col-lg-8 col-md-10 col-sm-12 col-xs-12 offset-md-1 offset-lg-2">-->
<!--                <div class="card shadow sm">-->
<!--                  <div class="card-body">-->
<!--                    <form action="javascript:void(0)" @submit="update" class="row text-start" enctype="multipart/form-data" method="post">-->
<!--                      <div class="form-group col-12 pb-2">-->
<!--                        <div class="row m-0 p-0">-->
<!--                          &lt;!&ndash; params: disabled-dates, available-dates, show-weeknumbers: true  :disabled-dates="disabledDates" &ndash;&gt;-->
<!--                          <date-picker-->
<!--                              :value="selectedDate"-->
<!--                              v-model="selectedDate"-->
<!--                              mode="date"-->
<!--                              :is-range=true-->
<!--                              :show-weeknumbers=true-->
<!--                              @input="updateCalendar"-->
<!--                              :attributes="attr"-->
<!--                              color="red"-->
<!--                          />-->
<!--                          &lt;!&ndash; :disabled-dates="{ weekdays: [1, 7] }" &ndash;&gt;-->
<!--                        </div>-->
<!--                      </div>-->

<!--                      <div class="col-12 mb-2 text-center">-->
<!--                        <button type="submit" :disabled="processing" class="btn btn-primary btn-block">-->
<!--                          {{ processing ? "Please wait" : "Update" }}-->
<!--                        </button>-->
<!--                        <p v-if="this.validationMessage" class="required-info text-center">-->
<!--                          {{ this.validationMessage }} Please scroll up to see error messages.-->
<!--                        </p>-->
<!--                        <p v-if="this.showError && this.validationSuccess" class="success-info text-center">-->
<!--                          {{ this.validationSuccess }}-->
<!--                        </p>-->
<!--                      </div>-->

<!--                    </form>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--          </div>-->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name:"DashboardUserCalendar",
  data(){
    return {
      selectedDate: [],
      disabledDates: [],
      processing:false,
      validationErrors: '',
      validationMessage: '',
      validationSuccess: '',
      showError: false
    }
  },
  mounted() {

  },
  computed:{
    attr() {
      return [
        {
          highlight:'red',
          dates: this.disabledDates,
        },

        {
          key: 'today',
          highlight: { color: 'green', fillMode: 'outline'},
          dates: new Date(),
        }
      ];

    },
  },
  methods: {
    containsObject(obj, list) {
      let objStartDate = Date.parse(obj.start);
      let objEndDate = Date.parse(obj.end);

      // Loop dates and check if within range of obj date
      for (let i = 0; i < list.length; i++) {
        let listElStartDate = Date.parse(list[i].start)
        let listElEndDate = Date.parse(list[i].end);

        // Example: objStartDate = 15.04.2022 objEndDate = 18.04.2022
        // List contains: ListStartDate = 15.04.2022, listEndDate = 22.04.2022
        // 15.04.2022 <= 15.04.2022 <= 18.04.2022 (true)
        // 18.04.2022 <= 22.04.2022 <= 15.04.2022 (false)
        // Check if start date within list date range
        if ((objStartDate >= listElStartDate && objStartDate <= listElEndDate)) {
          // If start date within list date range, check if end date is within or outside the list date range
          if ((objEndDate >= listElStartDate && objEndDate <= listElEndDate)) {
            // Scenario where start date within date range and end date is within list date range
            // Now we need to check if start date is equal to list start date
            if (objStartDate === listElStartDate) {
              // If end date is equal to list end date we totally remove obj from list date
              if (objEndDate === listElEndDate) {
                // ACTION (remove from list date)
                this.disabledDates = this.disabledDates.filter(function(el) { return (Date.parse(el.start) !== listElStartDate || Date.parse(el.end) !== listElEndDate); });
              } else {
                // ACTION (in list date, set new start date as obj end date + 1 (day) and leave list end date as it was)
                let newEndDate = new Date(objEndDate);
                newEndDate.setDate(newEndDate.getDate() + 1);

                this.disabledDates[i].start = newEndDate; // + 1 day
              }
            } else {
              // As obj start date is not equal to list start date, we will need to create at least one new data range entry
              // But prior to that its worth to check if obj end date is equal to list end date
              if (objEndDate === listElEndDate) {
                // ACTION (create new date range and add it to list: start: listElStartDate, end: objStartDate - 1)
                let newEndDate = new Date(objStartDate);
                newEndDate.setDate(newEndDate.getDate() - 1);

                let obj = {
                  start: list[i].start,
                  end: newEndDate
                }
                this.disabledDates.push(obj);

                // ACTION (remove from list date)
                this.disabledDates = this.disabledDates.filter(function(el) { return (Date.parse(el.start) !== listElStartDate || Date.parse(el.end) !== listElEndDate); });
              } else {
                // Scenario where start date not equal but within date range, end date not equal but within range
                // we will need to remove existing date range but also add two new date ranges (at beginning and at end)

                // ACTION (create new date range and add it to list: start: listElStartDate, end: objStartDate - 1)
                let newEndDate = new Date(objStartDate);
                newEndDate.setDate(newEndDate.getDate() - 1);

                let objStart = {
                  start: list[i].start,
                  end: newEndDate
                }
                this.disabledDates.push(objStart);

                // ACTION (create new date range and add it to list: start: objEndDate + 1, end: listElEndDate)
                let newStartDate = new Date(objEndDate);
                newStartDate.setDate(newStartDate.getDate() + 1);

                let objEnd = {
                  start: newStartDate,
                  end: list[i].end
                }
                this.disabledDates.push(objEnd);

                // ACTION (remove from list date)
                this.disabledDates = this.disabledDates.filter(function(el) { return (Date.parse(el.start) !== listElStartDate || Date.parse(el.end) !== listElEndDate); });
              }
            }

          } else {
            // Start date within date range but end date not withing date range
            // Now we need to check if start date is equal to list start date
            if (objStartDate === listElStartDate) {
              // ACTION (remove from list date)
              this.disabledDates = this.disabledDates.filter(function(el) { return (Date.parse(el.start) !== listElStartDate || Date.parse(el.end) !== listElEndDate); });
            } else {
              // Scenario where start date is not equal to list start date but still within date range

              // ACTION (create new date range and add it to list: start: listElStartDate, end: objStartDate - 1)
              let newEndDate = new Date(objStartDate);
              newEndDate.setDate(newEndDate.getDate() - 1);

              let objStart = {
                start: list[i].start,
                end: newEndDate.toString()
              }
              this.disabledDates.push(objStart);
            }

            // ACTION (remove from list date)
            this.disabledDates = this.disabledDates.filter(function(el) { return (Date.parse(el.start) !== listElStartDate || Date.parse(el.end) !== listElEndDate); });
          }
          // console.log('triggered change of date');
          // console.log(this.selectedDate);
          this.selectedDate = {start: new Date(), end: new Date()};
          // console.log(this.selectedDate);
          return true;
        } else {
          // Check if start date is before list start date, or after list end date
          if ((objStartDate < listElStartDate)) {
            // Check if objEndDate is within range
            if ((objEndDate >= listElStartDate)) {
              // MEAN START DATE IS BEFORE OUR DATE RANGE, so we need to check if it ends before date range or inside date range or after date range
              if ((objEndDate <= listElEndDate)) {
                // Scenario for within list date range or exactly at the end of list date range
                if (objEndDate === listElEndDate) {
                  // ACTION (remove from list date)
                  this.disabledDates = this.disabledDates.filter(function (el) {
                    return (Date.parse(el.start) !== listElStartDate || Date.parse(el.end) !== listElEndDate);
                  });
                } else {
                  // ACTION (create new date range and add it to list: start: objEndDate + 1, end: listElEndDate)
                  let newStartDate = new Date(objEndDate);
                  newStartDate.setDate(newStartDate.getDate() + 1);

                  let objEnd = {
                    start: newStartDate,
                    end: list[i].end
                  }
                  this.disabledDates.push(objEnd);

                  // ACTION (remove from list date)
                  this.disabledDates = this.disabledDates.filter(function (el) {
                    return (Date.parse(el.start) !== listElStartDate || Date.parse(el.end) !== listElEndDate);
                  });
                }
                // console.log('triggered change of date');
                // console.log(this.selectedDate);
                this.selectedDate = {start: new Date(), end: new Date()};
                // console.log(this.selectedDate);
                return true;
              } else {
                // Scenario for after list end date
                // ACTION (remove from list date)
                this.disabledDates = this.disabledDates.filter(function (el) {
                  return (Date.parse(el.start) !== listElStartDate || Date.parse(el.end) !== listElEndDate);
                });
              }
            }
          }
        }
      }
      return false;
    },
    async updateCalendar(dates) {
      // console.log(dates);
      let obj = { start: dates.start, end: dates.end };
      if (!this.containsObject(obj, this.disabledDates)) {
        // console.log(dates);
        this.disabledDates.push(obj);
      }
    },
    // async update() {
    //   this.processing = true
    //
    //   let formData = new FormData();
    //
    //   // Append all form data, also if statement changes true and false into 0 and 1
    //   for (let key in this.user) {
    //     if (this.user[key] === false) {
    //       this.user[key] = 0;
    //     } else if (this.user[key] === true) {
    //       this.user[key] = 1;
    //     }
    //     formData.append(key, this.user[key]);
    //   }
    //   // Append user image
    //   if (this.user.userImage.length !== 0) {
    //     this.user.userImage.forEach((userImage, index) => {
    //       formData.append("userImage[]", userImage)
    //     })
    //   }
    //
    //   await axios.get('/sanctum/csrf-cookie')
    //   await axios.post('api/user/' + this.user.id + '/update', formData).then(({data}) => {
    //     this.validationSuccess = data.success;
    //     this.showError = true;
    //     const timeout = setTimeout(() => {
    //       this.showError = false;
    //       this.validationSuccess = null;
    //       clearTimeout(timeout);
    //     }, 4000);
    //     this.validationErrors = null;
    //   }).catch(({response}) => {
    //     if (response && response.data && response.data.errors) {
    //       this.validationErrors = response.data.errors;
    //       this.validationMessage = response.data.message;
    //     }
    //     // alert(data.message)
    //   }).finally(() => {
    //     this.processing = false
    //
    //     // Refresh User Data
    //     this.$root.$emit('globalRefreshUser');
    //   })
    // }
  }
}
</script>


<style scoped lang="scss">

.dashboard-header {
  letter-spacing: 2px;
}

/* Start Upload File */
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 100px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1em;
  text-align: center;
  padding: 10px 0;
}

.uploading-user-image {
  width: 50%;
  margin: auto;
  max-width: 100%;
  height: auto;
  border-radius: 50%;
}

.delete-btn {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: block;
  margin: auto;
  font-size: 9px;
  padding: 0;
  transition: 0.6s;
  border: 0;
  color: white;
  background-color: #dc3545;
}

.delete-btn:hover {
  color: black;
  border: 1px solid #dc3545 !important;
  background-color: white;
  transition: 0.6s;
}

/* End Upload File */

</style>